.productReviewSection {
    background: #FFFFFF;
    box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.7);
    border-radius: 20px;
    padding: 25px 50px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    margin: auto;
    margin-top: -35px;

    .panel1 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: center;
        align-items: center;

        .title1 {
            font-weight: 600;
            font-size: 30px;
            line-height: 45px;
            color: #000000;
        }
        .title2 {
            font-weight: 600;
            font-size: 15px;
            line-height: 22px;
            color: #000000;
            
        }
    }
    .panel2 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: center;
        align-items: center;

        .title2 {
            font-weight: 600;
            font-size: 15px;
            line-height: 22px;
            color: #000000;
        }
        .bi-check{
            color: #FFC700;
            font-size: 35px !important;
            
        }
    }

    .panel3 {
        display: flex;
        flex-direction: row;
        gap: 15px;
        justify-content: center;
        align-items: center;

    }
}

@media only screen and (max-width: 595px) {
    .productReviewSectionDesktop {
        display: none !important;
    }
    .productReviewSection {
        width: 80%;
        padding: 5px 10px 20px 10px;
    }
    .productReviewSectionMobile {
        display: flex !important;
        flex-direction: column;
        .title1{
            font-size: 25px !important;
            line-height: 35px !important;
        }
        .title2{
            font-size: 12px !important;
            
        }
        .productReviewSectionMobilePanel1 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .chief {
                border: #FFB800 4px solid !important;
                width: 80px !important;
                height: 80px !important;
                left:24vw !important;
                top:-40px !important;
            }
            .panel1Mobile {
                display: flex;
                flex-direction: column;
                .panel1MobileFirst {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;
                }
            }

            .panel2Mobile {
                display: flex;
                flex-direction: column;
                .check{
                    border-radius: 100%;
                        box-shadow: 0px 2px 5px 0 rgba(0,0,0, 0.2), 0px 0px 4px 1px rgba(0,0,0, 0.1);
                    
                }
            }
        }

        .panel3 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-left: 0.2rem;
            .mastercard{
                margin-left:0.3rem !important;
            }
        }
    }
}