.dropbox-container{
    height: 50px; 
    padding-right: 1rem;
    display: flex;
    box-shadow: 0px 2px 5px 0 rgba(0,0,0, 0.3);
    border-radius: 50px;
    align-items: center;
    svg{
        color: #FFC700;
        width: 40px;
        margin-left: 5px;
    }
    .dropdown-toggle{
        border: none !important;
        font-size: 18px;
        border-radius: 50px;
        cursor: pointer;
    }
    .dropdown-menu{
        padding: 0.8rem;
        border: none;
        border-radius: 20px;
        width: 100%;
        min-width: 20px;
        box-shadow: 0px 2px 5px 0 rgba(0,0,0, 0.3);
        font-size: 18px;
        line-height: 30px;
        img{
            width: 30px;
            height: 30px;
        }
    }  
}
.icon-dropbox{
    box-shadow:none; 
    position: relative;
    .dropdown-menu{
        padding: 1.5rem;
        left: auto;
        position: absolute;
        right: 0;
        top: 40px;
        min-width: 20rem;
        .dropdown-item{
            overflow-wrap: break-word;
            white-space: normal;
            .adhoc-button{
                font-size: 18px;
                height: 30px;
            }
        }
    }
}
.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0; // remove the gap so it doesn't close
 }

@media screen and (max-width: 992px) {
    .dropbox-container {
        height: 40px;
        svg{
            width: 15px;
            margin:0 15px 0 10px;
        }
        .dropdown-toggle{
        font-size: 13px;
      }
      .dropdown-menu{
        font-size: 13px;
      }
    }
  }
@media screen and (max-width: 767px) {
    .icon-dropbox{
        .dropdown-menu{
            padding: 1.5rem;
            left: auto;
            position: absolute;
            right: 0;
            top: 25px;
            min-width: 15rem;
            .dropdown-item{
                overflow-wrap: break-word;
                white-space: normal;
                .adhoc-button{
                    font-size: 13px !important;
                    height: 30px;
                }
            }
        }
    }
}