.chief{
    left:0;
    bottom:20%;
    border: #FFB800 5px solid;
    border-radius: 100%;
    width: 70px;
    height: 70px;
    img{
        width: 100%;
        height: 100%;
    
    }
}
@media screen and (max-width: 1440px) {
        .chief{
                border: #FFB800 2px solid !important;
                width: 50px !important;
                height: 50px !important;
        }

  }

@media screen and (max-width: 992px) {
        .chief{
                width: 60px !important;
                height: 60px !important;
        }

  }