.finishPaymentSection {
    padding-top: 20px;
    text-align: left;
    .title1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 60px;
        color: #000000;
    }

    .box {
        margin-top: 20px;
        background: #FFFFFF;
        box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.7);
        border-radius: 20px;
        padding: 30px 110px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .title2 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 25px;
            line-height: 45px;
            color: #000000;
        }
        .boxPanel {
            display: grid;
            grid-template-columns: repeat(2,minmax(0,1fr));

            .boxOption {
                display: flex;
                flex-direction: row;
                align-items: center;
                border-bottom: 2px solid #FFB800;
                width: 445px;
                input {
                    margin: 20px;
                }
                input[type="radio"] {
                    width: 30px;
                    height: 30px;
                    cursor: pointer;
                }
                input[type="radio"]:after {
                    background-color: white;
                    border: 1px solid #FFB800;
                    outline: none !important;
                    border-radius: 50%;
                    content: '';
                    display: block;
                    height: 30px;
                    width: 30px;
                }
                input[type="radio"]:checked:after {
                    background-color: #FFB800;
                }
                label {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 38px;
                    color: #000000;
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .finishPaymentSection {
        .title1 {
            font-size: 25px;
        }
        .box {
            padding: 30px 30px;
            // gap: 30px;
    
            .title2 {
                font-size: 20px;
            }
    
            .boxPanel {
                display: grid;
                grid-template-columns: repeat(2,minmax(0,1fr));
    
                .boxOption {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    border-bottom: 2px solid #FFB800;
                    width: 302px;
                    input[type="radio"] {
                        width: 25px;
                        height: 25px;
                        cursor: pointer;
                    }
                    input[type="radio"]:after {
                        height: 25px;
                        width: 25px;
                    }
                    label {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .finishPaymentSection {
        .title1 {
            font-size: 23px;
        }
        .box {
            padding: 20px;
            gap: 30px;
    
            .title2 {
                font-size: 18px;
                display: none;
            }
    
            .boxPanel {
                display: grid;
                grid-template-columns: repeat(1,minmax(0,1fr));
    
                .boxOption {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    border-bottom: 2px solid #FFB800;
                    width: unset;

                    label {
                        font-size: 17px;
                    }
                }
            }
        }
    }
}