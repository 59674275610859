.modal {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    justify-content: center;
    z-index: 999999;
}

.modal-behide {
    background-color: transparent;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
}

